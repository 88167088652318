import { useState, useEffect } from 'react';
import { Message, Segment, Step, Grid, Header, Icon, Image } from 'semantic-ui-react';
import { useLocation } from "react-router-dom";
import { getCommonAPI } from 'hpp-fe-common';
import Brand from '../resources/logo.png';

function App() {
    const location = useLocation()

    const [malID] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const malID = searchParams.get('id');

        return malID;
    })

    const [foundID, setFoundID] = useState(true)
    const [loading, setLoading] = useState(false)
    const [malverify, setMalverify] = useState({})
    const [error, setError] = useState({
        error: false,
        errorMessage: '',
        errorMessageData: ''
    })

    const updateError = (errorData) => {
        setError(errorData)
        setLoading(false)
    }

    useEffect(() => {
        if (malID) {
            setLoading(true);

            getCommonAPI().get(`/api_v1/malverify/get-one?id=${malID}`)
                .then(({ data }) => {
                    if (data.error) return updateError(data)
                    setMalverify(data.malverify)
                    setLoading(false);
                })
                .catch((error) => {
                    updateError({
                        error: true,
                        errorMessage: 'Error in loading uploaded invoice file. Please contact system administrator',
                        errorMessageData: error.toString()
                    })
                })
        } else {
            setFoundID(false)
            setLoading(false);
        }
    }, [malID])



    return (

        <>
            {
                !foundID &&
                <Segment className='login-form' >
                    <style>{`
         body > div,
         body > div > div,
         body > div > div > div.login-form {
           height: 100%;
         }
       `}</style>
                    <Grid centered style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Grid textAlign='center'>
                                <Grid.Row>
                                    <Image src={Brand} size='small' centered />
                                    <Header as='h3' textAlign='center'>
                                        Welcome to Manufacture Authorization Letter Verification
                                        <Header.Subheader>
                                            Hikvision South Asia
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Row>
                            </Grid>
                            <Segment basic textAlign='center'>
                                <Grid.Row >
                                    <Message warning>
                                        Please scan your QR code to initiate the Manufacturer's Authorization Letter verification process.
                                    </Message>
                                </Grid.Row>
                            </Segment>

                        </Grid.Column>
                    </Grid>

                </Segment>
            }


            {
                foundID &&
                // <div className='login-form'>
                <Segment className='login-form' loading={loading}>
                    <style>{`
         body > div,
         body > div > div,
         body > div > div > div.login-form {
           height: 100%;
         }
       `}
                    </style>
                    <Grid centered style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Grid textAlign='center'>
                                <Grid.Row>
                                    <Image src={Brand} size='small' centered />
                                    <Header as='h3' textAlign='center'>
                                        Manufacturer's Authorization Letter Verification
                                        <Header.Subheader>
                                            Hikvision South Asia
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Row>
                            </Grid>
                            {
                                error.error &&
                                <Segment basic textAlign='center'>
                                    <Message negative>
                                        <Message.Header>{error.errorMessage}</Message.Header>
                                        <p>{error.errorMessageData}</p>
                                    </Message>
                                </Segment>
                            }

                            <Grid.Row textAlign='left'>
                                {!error.error && (
                                    <Segment stacked style={{ marginTop: '10px' }}>
                                        {loading && <h3>Loading.</h3>}

                                        <>
                                            <Grid textAlign='center' style={{ marginTop: '10px' }}>
                                                <Icon circular inverted color='teal' name='check' size='big' />
                                                <h4 style={{ marginBottom: '10px' }}>
                                                    This is a genuine manufacturer's authorization letter.
                                                </h4>
                                            </Grid>
                                            <Step.Group vertical fluid>
                                                <Step completed>
                                                    <Icon name='truck' />
                                                    <Step.Content>
                                                        <Step.Title>Tender Name</Step.Title>
                                                        <Step.Description>{malverify?.tenderName}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                                <Step completed>
                                                    <Icon name='payment' />
                                                    <Step.Content>
                                                        <Step.Title>Tender Reference Number</Step.Title>
                                                        <Step.Description>{malverify?.tenderRefNo}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                                <Step completed>
                                                    <Icon name='payment' />
                                                    <Step.Content>
                                                        <Step.Title>Letter Issued To</Step.Title>
                                                        <Step.Description>{malverify?.partnerCompany}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                            </Step.Group>
                                        </>
                                    </Segment>
                                )}
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                    {/* </div> */}
                </Segment>
            }

        </>

    );
}

export default App;

