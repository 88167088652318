import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes } from 'react-router-dom';
import { CustomBrowserRouter } from 'hpp-fe-common';


//components import
import App from './app/App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <CustomBrowserRouter>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </CustomBrowserRouter>
);




